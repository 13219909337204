<template>
  <span>
    <div v-for="item in dates" :key="item.id">
      <span v-if="item.value" :id="item.id" class="d-block text-nowrap">
        <small>
          <font-awesome-icon :icon="item.icon" :class="item.color" fixed-width />
          {{ item.value }}<br>
        </small>
      </span>
      <b-tooltip v-if="item.value" :target="item.id" triggers="hover" variant="primary">
        {{ item.tooltip }}
      </b-tooltip>
    </div>
  </span>
</template>

<script>
import {
  VBTooltip, BTooltip,
} from 'bootstrap-vue'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'
import { format } from 'date-fns'

export default {
  components: {
    BTooltip,
  },
  props: {
    collection: {
      type: Object,
      default: null,
    },
    viewDetail: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  computed: {
    dates() {
      if (this.collection) {
        return [
          {
            id: `spStartDate_${this.collection.id}`,
            icon: ['far', 'clock'],
            color: 'text-secondary',
            value: this.collection.date_created ? format(new Date(this.collection.date_created), 'dd/MM/yyyy HH:mm:ss') : '',
            tooltip: this.$i18n.t('components.cards.meli.mp.collections.created_date'),
          },
          {
            id: `spApprovedDate_${this.collection.id}`,
            icon: ['fas', 'check'],
            color: 'text-info',
            value: this.collection.date_approved ? format(new Date(this.collection.date_approved), 'dd/MM/yyyy HH:mm:ss') : '',
            tooltip: this.$i18n.t('components.cards.meli.mp.collections.approved_date'),
          },
          {
            id: `spReleaseDate_${this.collection.id}`,
            icon: ['fas', 'dollar-sign'],
            color: 'text-success',
            value: this.collection.money_release_date ? format(new Date(this.collection.money_release_date), 'dd/MM/yyyy HH:mm:ss') : '',
            tooltip: this.$i18n.t('components.cards.meli.mp.collections.release_date'),
          },
        ]
      }
      return []
    },
  },
}
</script>
