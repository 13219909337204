!<template>
  <span>
    <span>
      <h4 :class="'text-' + getPaymentColor(collection.status)">{{ formatMoney(collection.transaction_amount) }}</h4>
      <span :class="!stacked?'d-block text-nowrap':''">
        {{ description }}<br>
      </span>
      <small>
        <b-link :id="'lnkCopyId' + collection.id" v-clipboard:copy="collection.id" v-clipboard:success="clipboardSuccessHandler"
          data-copy-field="ID">
          <font-awesome-icon :icon="['far', 'copy']" fixed-width />
        </b-link>
        <b-tooltip :target="'lnkCopyId' + collection.id" triggers="hover" variant="primary">
          {{ $t('common.copy', {'field': $t('meli.identification.id')}) }}
        </b-tooltip>
        #{{ collection.id }}
      </small>
      <span v-if="internalShowDetail">
        <br/>
        <span v-if="collection.fee_details">
          <small class="text-danger" v-for="fee in collection.fee_details" :key="fee.type">{{ $t('components.cards.meli.mp.collections.fees.' + fee.type)}}: {{ formatMoney(fee.amount) }} ({{ formatNumber(fee.amount/collection.transaction_amount*100, 2, '', '%') }})<br/></small>
        </span>
        <small v-if="collection.transaction_details.net_received_amount > 0" :class="'text-' + getPaymentColor(collection.status)">Valor Líquido: {{ formatMoney(collection.transaction_details.net_received_amount)
          }}</small><br />
      </span>
    </span>
    <b-row v-if="viewDetail" no-gutters class="mt-50">
      <b-col>
        <span v-if="collection.order && collection.order.type && collection.order.type === 'mercadolibre'">
          <b-button :id="'btnViewOrder' + collection.id"
            size="sm"
            variant="primary" class="btn-rad mr-50"
            :href="'https://myaccount.mercadolivre.com.br/sales/vop?&orderId=' + collection.order.id" target="_blank">
            <font-awesome-icon :icon="['fas', 'shopping-cart']" fixed-width />
            <span> {{ $t('components.cards.meli.mp.collections.order')}}</span>
          </b-button>
          <b-tooltip :target="'btnViewOrder' + collection.id" triggers="hover" variant="primary">
            {{ $t('components.cards.meli.mp.collections.view_order')}}
          </b-tooltip>
        </span>
        <span v-if="collection.payment_type_id === 'ticket'">
          <span v-if="collection.transaction_details && collection.transaction_details.external_resource_url">
            <b-button :id="'btnViewTicket' + collection.id"
              size="sm"
              variant="primary" class="btn-rad" :href="collection.transaction_details.external_resource_url" target="_blank">
              <font-awesome-icon :icon="['fas', 'barcode']" fixed-width />
              <span> {{ $t('components.cards.meli.mp.collections.ticket')}}</span>
            </b-button>
            <b-tooltip :target="'btnViewTicket' + collection.id" triggers="hover" variant="primary">
              {{ $t('components.cards.meli.mp.collections.view_ticket')}}
            </b-tooltip>
          </span>
          <span v-else>
            <b-button :id="'btnViewTicket' + collection.id" size="sm" variant="primary" class="btn-rad" @click="showTicket = !showTicket">
              <font-awesome-icon :icon="['fas', showTicket?'eye-slash':'eye']" fixed-width />
              <span> {{ $t('components.cards.meli.mp.collections.ticket')}}</span>
            </b-button>
            <b-tooltip :target="'btnViewTicket' + collection.id" triggers="hover" variant="primary">
              {{ $t('components.cards.meli.mp.collections.view_ticket')}}
            </b-tooltip>
            <span v-if="showTicket && collection.barcode.content" class="small">
              <br/><br /><strong>{{ $t('components.cards.meli.mp.collections.barcode_line')}}:</strong> {{ collection.barcode.content }}<br/>
              <strong>{{ $t('components.cards.meli.mp.collections.expire_date')}}:</strong> {{ expireDate }}
            </span>
          </span>
        </span>
      </b-col>
    </b-row>
    <b-row v-if="!viewDetail" no-gutters style="margin-top: 8px;">
      <b-col>
        <span v-if="collection.order && collection.order.type && collection.order.type === 'mercadolibre'">
          <b-button :id="'btnViewOrder' + collection.id"
            size="sm" variant="danger"
            class="btn-rad">
            <font-awesome-icon :icon="['fas', 'shopping-cart']" fixed-width />
            <span> {{ $t('components.cards.meli.mp.collections.order')}}</span>
          </b-button>
          <b-popover :target="'btnViewOrder' + collection.id" triggers="hover" placement="top">
            <template v-slot:title>{{ $t('components.cards.meli.mp.collections.view_order')}}</template>
            {{ $t('common.info.payers_only_funtionality') }}
          </b-popover>
        </span>
        <span v-if="collection.transaction_details && collection.transaction_details.external_resource_url">
          <b-button :id="'btnViewTicket' + collection.id"
            size="sm"
            variant="danger" class="btn-rad">
            <font-awesome-icon :icon="['fas', 'barcode']" fixed-width />
            <span> {{ $t('components.cards.meli.mp.collections.ticket')}}</span>
          </b-button>
          <b-popover :target="'btnViewTicket' + collection.id" triggers="hover" placement="top">
            <template v-slot:title>{{ $t('components.cards.meli.mp.collections.view_ticket')}}</template>
            {{ $t('common.info.payers_only_funtionality') }}
          </b-popover>
        </span>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import {
  BRow, BCol, BButton, BLink, VBTooltip, BTooltip, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'
import { format } from 'date-fns'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BTooltip,
    BPopover,
  },
  props: {
    collection: {
      type: Object,
      default: null,
    },
    viewDetail: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  data() {
    return {
      showTicket: false,
      internalShowDetail: false,
    }
  },
  computed: {
    description() {
      if (this.collection.description === 'marketplace_shipment') {
        return this.$i18n.t('components.cards.meli.mp.collections.marketplace_shipment')
      }
      return this.collection.description
    },
    expireDate() {
      return format(new Date(this.collection.date_of_expiration), 'dd/MM/yyyy')
    },
    stacked() {
      if (this.options && this.options.tableStacked) {
        return this.options.tableStacked
      }
      return false
    },
  },
  watch: {
    showDetail() {
      this.internalShowDetail = this.showDetail
    },
  },
}
</script>
