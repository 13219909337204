<template>
  <b-input-group class="input-group-merge">
    <v-select class="form-control" v-model="tag" multiple label="text" :placeholder="$t('common.type_something_to_search')"
      :options="options" @search="fetchOptions" @option:selected="executeSearch" @option:deselected="executeSearch">
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          <span v-html="$t('common.no_results_for', {search: search})"></span>
        </template>
        <em v-else style="opacity: 0.5">{{ $t('common.type_something_to_search') }}</em>
      </template>
    </v-select>
    <b-input-group-append is-text v-if="false">
      <font-awesome-icon :icon="['fas', 'search']" class="text-primary" fixed-width @click="executeSearch" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import meliHelper from '@core-md/mixins/meliHelper'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  props: {
    filter: {
      type: Array,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    meliHelper,
  ],
  data() {
    return {
      tag: '',
      options: [],
      processing: false,
      collectionStatus: [
        { id: 'approved', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.approved') },
        { id: 'pending', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.pending') },
        { id: 'rejected', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.rejected') },
        { id: 'cancelled', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.cancelled') },
        { id: 'in_mediation', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.in_mediation') },
        { id: 'refunded', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.refunded') },
        { id: 'authorized', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.authorized') },
        { id: 'charged_back', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.charged_back') },
        { id: 'in_process', text: this.$i18n.t('components.cards.meli.mp.collections.statuses.in_process') },
      ],
      collectionType: [
        { id: 'credit_card', text: this.$i18n.t('components.cards.meli.mp.collections.types.credit_card') },
        { id: 'debit_card', text: this.$i18n.t('components.cards.meli.mp.collections.types.debit_card') },
        { id: 'ticket', text: this.$i18n.t('components.cards.meli.mp.collections.types.ticket') },
        { id: 'account_money', text: this.$i18n.t('components.cards.meli.mp.collections.types.account_money') },
        { id: 'atm', text: this.$i18n.t('components.cards.meli.mp.collections.types.atm') },
        { id: 'bank_transfer', text: this.$i18n.t('components.cards.meli.mp.collections.types.bank_transfer') },
        { id: 'digital_currency', text: this.$i18n.t('components.cards.meli.mp.collections.types.digital_currency') },
        { id: 'digital_wallet', text: this.$i18n.t('components.cards.meli.mp.collections.types.digital_wallet') },
      ],
      collectionMethod: [
        { id: 'visa', text: this.$i18n.t('components.cards.meli.mp.collections.methods.visa') },
        { id: 'master', text: this.$i18n.t('components.cards.meli.mp.collections.methods.master') },
        { id: 'elo', text: this.$i18n.t('components.cards.meli.mp.collections.methods.elo') },
        { id: 'amex', text: this.$i18n.t('components.cards.meli.mp.collections.methods.amex') },
        { id: 'hipercard', text: this.$i18n.t('components.cards.meli.mp.collections.methods.hipercard') },
        { id: 'aura', text: this.$i18n.t('components.cards.meli.mp.collections.methods.aura') },
        { id: 'diners', text: this.$i18n.t('components.cards.meli.mp.collections.methods.diners') },
        { id: 'melicard', text: this.$i18n.t('components.cards.meli.mp.collections.methods.melicard') },
        { id: 'debvisa', text: this.$i18n.t('components.cards.meli.mp.collections.methods.debvisa') },
        { id: 'debmaster', text: this.$i18n.t('components.cards.meli.mp.collections.methods.debmaster') },
        { id: 'debelo', text: this.$i18n.t('components.cards.meli.mp.collections.methods.debelo') },
        { id: 'consumer_credits', text: this.$i18n.t('components.cards.meli.mp.collections.methods.consumer_credits') },
        { id: 'giftcard', text: this.$i18n.t('components.cards.meli.mp.collections.methods.giftcard') },
        { id: 'paypal', text: this.$i18n.t('components.cards.meli.mp.collections.methods.paypal') },
        { id: 'pec', text: this.$i18n.t('components.cards.meli.mp.collections.methods.pec') },
        { id: 'pix', text: this.$i18n.t('components.cards.meli.mp.collections.methods.pix') },
      ],
    }
  },
  computed: {
    timezoneFormatComplete() {
      return 'yyyy-MM-ddTHH:mm:ss.000-03:00'
    },
  },
  methods: {
    fetchOptions(search) {
      if (search) {
        const autocompleteItems = []
        const patt = new RegExp(/^[0-9]*$/)
        const isId = patt.test(search)
        if (isId) {
          autocompleteItems.push({ option: 'id', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.id') + ': ' + search }) // eslint-disable-line
          autocompleteItems.push({ option: 'payer.id', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.payer_id') + ': ' + search }) // eslint-disable-line
          if (search < 366) {
            // autocompleteItems.push(this.getRangeCriteria('created', 'day', (-1) * search, this.timezoneFormatComplete))
          }
        } else {
          // autocompleteItems.push({ option: 'payer.email', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.payer') + ': ' + search }) // eslint-disable-line
        }

        if (search.length > 1) {
          const collectionStatusItems = this.collectionStatus.filter(i => i.text.toLowerCase().indexOf(search.toLowerCase()) !== -1)

          collectionStatusItems.forEach(value => {
            autocompleteItems.push({ option: 'status', value_id: value.id, text: value.text })
          })

          const collectionTypeItems = this.collectionType.filter(i => i.text.toLowerCase().indexOf(search.toLowerCase()) !== -1)

          collectionTypeItems.forEach(value => {
            autocompleteItems.push({ option: 'payment_type_id', value_id: value.id, text: value.text })
          })

          const collectionMethodItems = this.collectionMethod.filter(i => i.text.toLowerCase().indexOf(search.toLowerCase()) !== -1)

          collectionMethodItems.forEach(value => {
            autocompleteItems.push({ option: 'payment_method_id', value_id: value.id, text: value.text })
          })
        }

        /*
        if (search.toLowerCase().indexOf('h') === 0) {
          autocompleteItems.push(this.getRangeCriteria('approved', 'day', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', 0, this.timezoneFormatComplete))
        }

        if (search.toLowerCase().indexOf('o') === 0) {
          autocompleteItems.push(this.getRangeCriteria('approved', 'day', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', -1, this.timezoneFormatComplete))
        }

        if (search.toLowerCase().indexOf('s') === 0) {
          autocompleteItems.push(this.getRangeCriteria('approved', 'week', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('approved', 'week', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'week', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'week', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('created', 'week', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('created', 'week', -1, this.timezoneFormatComplete))
        }

        if (search.toLowerCase().indexOf('m') === 0) {
          autocompleteItems.push(this.getRangeCriteria('approved', 'month', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('approved', 'month', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'month', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'month', -1, this.timezoneFormatComplete))
        }

        if (search.toLowerCase().indexOf('a') === 0) {
          autocompleteItems.push(this.getRangeCriteria('approved', 'year', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('approved', 'year', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'year', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'year', -1, this.timezoneFormatComplete))
        }

        if (search.toLowerCase().indexOf('li') >= 0) {
          autocompleteItems.push(this.getRangeCriteria('release', 'day', 1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', 2, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', 60, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'day', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'week', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'week', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'month', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'month', -1, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'year', 0, this.timezoneFormatComplete))
          autocompleteItems.push(this.getRangeCriteria('release', 'year', -1, this.timezoneFormatComplete))
        }
        */

        this.options = autocompleteItems
      }
    },
    clearSearch() {
      this.tag = []
      this.executeSearch()
    },
    executeSearch() {
      this.$emit('update:filter', this.tag)
      this.$emit('search')
    },
  },
  watch: {
    filter() {
      this.tag = this.filter
    },
  },
}
</script>

<style lang="css">
  .v-select {
    padding: 0px 0px !important;
  }

  .v-select .vs__dropdown-toggle {
    padding: 0px 0px;
    border: 0px;
  }

  .form-control {
    min-height: 2.714rem;
    height: unset !important;
  }

  .vs__deselect {
    fill: #fff !important;
  }
</style>
