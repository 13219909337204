<template>
  <span class="m-0 p-0">
    <span v-if="payer.first_name" class="font-weight-bold d-block text-nowrap">
      {{ fullname }} <font-awesome-icon v-if="!isSplitter" :icon="['fas', internalShowDetail?'eye-slash':'eye']" fixed-width class="text-primary" @click.prevent="internalShowDetail = !internalShowDetail" /><br>
    </span>
    <span v-else>
      {{ $t('components.cards.meli.mp.collections.name_unavailable') }}
      <font-awesome-icon :id="'iNameUnavailable' + collectionId" :icon="['fas', 'info-circle']" class="text-primary" fixed-width /><br />
      <b-popover :target="'iNameUnavailable' + collectionId" triggers="hover" placement="top">
        <template v-slot:title>{{ $t('components.cards.meli.mp.collections.name_unavailable_title') }}</template>
        {{ $t('components.cards.meli.mp.collections.name_unavailable_info') }}
      </b-popover>
    </span>
    <span v-if="internalShowDetail">
      <span v-if="hasPhone(payer.phone)">
        <span v-if="viewDetail">
          <small>
            <b-link :id="'lnkCopyPhone' + collectionId"
              v-clipboard:copy="getPhone('', payer.phone.area_code, payer.phone.number)"
              v-clipboard:success="clipboardSuccessHandler" :data-copy-field="$t('common.phone')">
              <font-awesome-icon :icon="['far', 'copy']" fixed-width />
            </b-link>
            {{ getPhone('', payer.phone.area_code, payer.phone.number, true) }}<br>
          </small>
          <b-tooltip :target="'lnkCopyPhone' + collectionId" triggers="hover" variant="primary">
            {{ $t('common.copy', {'field': $t('common.phone')}) }}
          </b-tooltip>
        </span>
        <span v-else>
          {{ $t('components.cards.meli.mp.collections.phone_unavailable') }}
          <font-awesome-icon :id="'iPhoneUnavailable' + collectionId" :icon="['fas', 'info-circle']" class="text-primary"
              fixed-width /><br />
          <b-popover :target="'iPhoneUnavailable' + collectionId" triggers="hover" placement="top">
            <template v-slot:title>{{ $t('components.cards.meli.mp.collections.phone_unavailable_title') }}</template>
            {{ $t('common.info.payers_only_info') }}
          </b-popover>
        </span>
      </span>
      <span v-if="hasIdentification(payer.identification)" class="d-block text-nowrap">
        <span v-if="viewDetail">
          <small>
            <b-link :id="'lnkCopyIdentificationType' + collectionId" v-clipboard:copy="payer.identification.number"
              v-clipboard:success="clipboardSuccessHandler" :data-copy-field="payer.identification.type">
              <font-awesome-icon :icon="['far', 'copy']" fixed-width />
            </b-link>
            {{ payer.identification.type }}: {{ formatDocument(payer.identification.number) }}<br>
          </small>
          <b-tooltip :target="'lnkCopyIdentificationType' + collectionId" triggers="hover" variant="primary">
            {{ $t('common.copy', {'field': payer.identification.type}) }}
          </b-tooltip>
        </span>
        <span v-else>
          {{ $t('components.cards.meli.mp.collections.identification_unavailable') }}
          <font-awesome-icon :id="'iIdentificationUnavailable' + collectionId" :icon="['fas', 'info-circle']" class="text-primary"
              fixed-width /><br />
          <b-popover :target="'iIdentificationUnavailable' + collectionId" triggers="hover" placement="top">
            <template v-slot:title>{{ $t('components.cards.meli.mp.collections.identification_unavailable_title') }}</template>
            {{ $t('common.info.payers_only_info') }}
          </b-popover>
        </span>
      </span>
      <span v-if="payer.email">
        <span v-if="viewDetail">
          <small>
            <b-link :id="'lnkCopyEmail' + collectionId" v-clipboard:copy="payer.email"
              v-clipboard:success="clipboardSuccessHandler" :data-copy-field="$t('common.email')">
              <font-awesome-icon :icon="['far', 'copy']" fixed-width />
            </b-link>
            {{ payer.email }}<br>
          </small>
          <b-tooltip :target="'lnkCopyEmail' + collectionId" triggers="hover" variant="primary">
            {{ $t('common.copy', {'field': $t('common.email')}) }}
          </b-tooltip>
        </span>
        <span v-else>
          {{ $t('components.cards.meli.mp.collections.email_unavailable') }}
          <font-awesome-icon :id="'iEmailUnavailable' + collectionId" :icon="['fas', 'info-circle']" class="text-primary"
              fixed-width /><br />
          <b-popover :target="'iEmailUnavailable' + collectionId" triggers="hover" placement="top">
            <template v-slot:title>{{ $t('components.cards.meli.mp.collections.email_unavailable_title') }}</template>
            {{ $t('common.info.payers_only_info') }}
          </b-popover>
        </span>
      </span>
    </span>
    <small v-if="!isSplitter" class="d-block text-nowrap">
      <b-link :id="'lnkCopyNickname' + collectionId" v-clipboard:copy="payer.nickname"
        v-clipboard:success="clipboardSuccessHandler" :data-copy-field="$t('meli.identification.nickname')">
        <font-awesome-icon :icon="['far', 'copy']" fixed-width />
      </b-link>
      <b-tooltip :target="'lnkCopyNickname' + collectionId" triggers="hover" variant="primary">
        {{ $t('common.copy', {'field': $t('meli.identification.nickname')}) }}
      </b-tooltip>
      <b-link :href="payer.permalink" target="_blank"> {{payer.nickname}} <span v-if="payer.points">({{payer.points}})</span>
      </b-link>
      <br/>
      <b-link :id="'lnkCopyPayerId' + collectionId" v-clipboard:copy="payer.id"
        v-clipboard:success="clipboardSuccessHandler" :data-copy-field="$t('meli.identification.id')">
        <font-awesome-icon :icon="['far', 'copy']" fixed-width />
      </b-link>
      <b-tooltip :target="'lnkCopyPayerId' + collectionId" triggers="hover" variant="primary">
        {{ $t('common.copy', {'field': $t('meli.identification.id')}) }}
      </b-tooltip>
      {{ $t('meli.identification.id') }}: {{ payer.id }}<br/>
    </small>
    <span v-if="internalShowDetail">
      <b-row class="no-margin">
        <b-col>
          <span v-if="hasPhone(payer.phone)">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :id="'btnWhatsapp' + collectionId" size="sm" :variant="viewDetail?'success':'danger'" class="mr-50 mt-50"
              @click.prevent="viewDetail && openWhatsapp(getPhone('55', payer.phone.area_code, payer.phone.number))">
              <font-awesome-icon :icon="['fab', 'whatsapp']" fixed-width />
              <span> {{ $t('common.whatsapp') }}</span>
            </b-button>
            <b-tooltip v-if="viewDetail" :target="'btnWhatsapp' + collectionId" triggers="hover" variant="primary">
              {{ $t('common.chat_whatsapp') }}
            </b-tooltip>
            <b-popover v-else :target="'btnWhatsapp' + collectionId" triggers="hover" placement="top">
              <template v-slot:title>{{ $t('common.chat_whatsapp') }}</template>
              {{ $t('common.info.payers_only_funtionality') }}
            </b-popover>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :id="'btnCall' + collectionId" size="sm" :variant="viewDetail?'outline-primary':'danger'" class="mr-50 mt-50"
              @click.prevent="viewDetail && openPhone(getPhone('', payer.phone.area_code, payer.phone.number))">
              <font-awesome-icon :icon="['fas', 'phone']" fixed-width />
              <span> {{ $t('common.call') }}</span>
            </b-button>
            <b-tooltip v-if="viewDetail" :target="'btnCall' + collectionId" triggers="hover" variant="primary">
              {{ $t('common.call_phone') }}
            </b-tooltip>
            <b-popover v-else :target="'btnCall' + collectionId" triggers="hover" placement="top">
              <template v-slot:title>{{ $t('common.call_phone') }}</template>
              {{ $t('common.info.payers_only_funtionality') }}
            </b-popover>
          </span>
          <span v-if="payer.email">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :id="'btnEmail' + collectionId" size="sm" :variant="viewDetail?'outline-primary':'danger'" class="mr-50 mt-50"
              :href="viewDetail?'mailto:' + payer.email:''">
              <font-awesome-icon :icon="['fas', 'envelope']" fixed-width />
              <span> {{ $t('common.email') }}</span>
            </b-button>
            <b-tooltip v-if="viewDetail" :target="'btnEmail' + collectionId" triggers="hover" variant="primary">
              {{ $t('common.send_email') }}
            </b-tooltip>
            <b-popover v-else :target="'btnEmail' + collectionId" triggers="hover" placement="top">
              <template v-slot:title>{{ $t('common.send_email') }}</template>
              {{ $t('common.info.payers_only_funtionality') }}
            </b-popover>
          </span>
        </b-col>
      </b-row>
    </span>
  </span>
</template>

<script>
import {
  BRow, BCol, BButton, BLink, VBTooltip, BTooltip, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import stringHelper from '@core-md/mixins/stringHelper'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BTooltip,
    BPopover,
  },
  props: {
    payer: {
      type: Object,
      default: null,
    },
    collectionId: {
      type: Number,
      default: null,
    },
    viewDetail: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    stringHelper,
  ],
  data() {
    return {
      internalShowDetail: false,
    }
  },
  computed: {
    isSplitter() {
      return (this.payer && this.payer.first_name && this.payer.first_name.indexOf('Splitter') >= 0)
    },
    fullname() {
      let name = ''
      if (this.payer && this.payer.first_name) {
        if (this.isSplitter) {
          name = this.$i18n.t('components.cards.meli.mp.collections.splitter')
        } else {
          name = this.payer.first_name
          if (this.payer.last_name) {
            name += ` ${this.payer.last_name}`
          }
        }
      }
      return this.decodeData(name).substring(0, 40)
    },
    phoneNumber() {
      let phone = ''
      if (this.payer && this.payer.phone) {
        phone += this.payer.phone.area_code ? this.payer.phone.area_code.trim() : ''
        phone += this.payer.phone.number ? this.payer.phone.number.trim() : ''
      }
      return phone
    },
  },
  methods: {
    hasIdentification(identification) {
      return identification ? (identification.type || identification.number) : false
    },
    hasPhone(phone) {
      if (phone && !Array.isArray(phone)) {
        const n = this.getPhone('', phone.area_code, phone.number)
        if (n.length > 5) {
          return true
        }
      }
      return false
    },
    openPhone(phoneNumber) {
      window.open(`tel: ${phoneNumber}`, '_blank')
    },
    openWhatsapp(phoneNumber) {
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank')
    },
  },
  watch: {
    showDetail() {
      this.internalShowDetail = this.showDetail
    },
  },
}
</script>
