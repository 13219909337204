import meliClient from './core/client'

export default {
  getCollections(request) {
    const body = {
      method: 'GET',
      url: '/v1/payments/search',
      parameters: {
        access_token: request.accessToken,
        id: request.id ? request.id : null,
        'collector.id': request.collectorId ? request.collectorId : null,
        'payer.id': request.payerId ? request.payerId : null,
        'payer.email': request.payerEmail ? request.payerEmail : null,
        status: request.status ? request.status : null,
        payment_type_id: request.paymentTypeId ? request.paymentTypeId : null,
        payment_method_id: request.paymentMethodId ? request.paymentMethodId : null,
        sort: request.sortBy,
        criteria: request.sortDirection,
        limit: request.limit,
        offset: request.offset,
      },
    }

    if (request.range) {
      body.parameters.range = request.range.value_id
      body.parameters.begin_date = request.range.begin_date
      body.parameters.end_date = request.range.end_date
    }

    return meliClient.callMpProcess(body)
  },
}
