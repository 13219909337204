<template>
  <span>
    <b-badge pill :variant="getPaymentColor(collection.status)">
      <font-awesome-icon :icon="getPaymentStatusIcon(collection.status)" fixed-width />
      {{ getPaymentStatus(collection.status) }}</b-badge> <font-awesome-icon :icon="['fas', internalShowDetail?'eye-slash':'eye']" fixed-width class="text-primary"
        @click.prevent="internalShowDetail = !internalShowDetail" /><br>
    <img :src="getPaymentLogo(collection.payment_type_id, collection.payment_method_id)"><br />
    <span v-if="internalShowDetail">{{ getPaymentType(collection.payment_type_id, collection.payment_method_id)
      }}<br /></span>
    <span v-if="internalShowDetail && collection.card && collection.card.first_six_digits && collection.card.last_four_digits">
      <span v-if="viewDetail">
        <small>
          {{ collection.card.first_six_digits }}******{{ collection.card.last_four_digits }}
        </small>
      </span>
      <span v-if="!viewDetail">
        {{ $t('components.cards.meli.mp.collections.details_unavailable') }} <font-awesome-icon :id="'iCardDetails' + collection.id" :icon="['fas', 'info-circle']" class="text-primary" fixed-width /><br />
        <b-popover :target="'iCardDetails' + collection.id" triggers="hover" placement="top">
          <template v-slot:title>{{ $t('components.cards.meli.mp.collections.details_unavailable_title') }}</template>
          {{ $t('common.info.payers_only_funtionality') }}
        </b-popover>
      </span>
    </span>
    <span v-if="internalShowDetail && collection.installments && collection.installments > 1">
      <span v-if="viewDetail">
        <br />
        <small>
          {{ collection.installments }} x
          {{ formatMoney(collection.transaction_details.installment_amount, false) }} =
          {{ formatMoney(collection.installments * collection.transaction_details.installment_amount) }}
        </small>
      </span>
    </span>
    <span v-if="internalShowDetail && collection.authorization_code">
      <span v-if="viewDetail">
        <br />
        <small>
          {{ $t('components.cards.meli.mp.collections.aut') }}: {{ collection.authorization_code }}
        </small>
      </span>
    </span>
  </span>
</template>

<script>
import {
  VBTooltip, BPopover, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'

export default {
  components: {
    BPopover,
    BBadge,
  },
  props: {
    collection: {
      type: Object,
      default: null,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    viewDetail: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  data() {
    return {
      internalShowDetail: false,
    }
  },
  watch: {
    showDetail() {
      this.internalShowDetail = this.showDetail
    },
  },
}
</script>
