<template>
  <b-card no-body style="min-height:400px;">
    <b-card-header v-if="options.showTitle || options.showAccount" class="p-0">
      <div>
        <b-card-title v-if="options.showTitle" class="mb-1">
          {{ $t('components.cards.meli.mp.collections.page_title') }}
        </b-card-title>
        <b-card-sub-title v-if="options.showAccount && !account">
          <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
          <span v-else>{{ $t('components.cards.common.select_account') }}</span>
        </b-card-sub-title>
      </div>
      <div class="d-flex align-items-center">
        <simple-account-selector v-if="!inherit && !account" @change="changeSelectedAccount"></simple-account-selector>
      </div>
    </b-card-header>

    <b-card-body class="p-0">
      <b-row class="p-1">
        <b-col sm="10" md="10" lg="6" xl="4" class="mb-1">
          <search :filter.sync="filter" @search="getData"/>
        </b-col>
        <b-col class="mb-1">
          <toolbar :options.sync="cardCollectionsOptions.options" :pagination.sync="pagination"
            @executeFilter="executeFilter" @executeSort="executeSort" @downloadFile="downloadFile"/>
        </b-col>
      </b-row>

      <table-info :showLoading="formSubmitted" :showNotFound="notFound"></table-info>

      <b-row v-if="!notFound" class="pl-sm-1 pr-sm-1">
        <b-col cols="6" sm="6" order-sm="1" align-self="center">
          <paginator-summary :pagination="pagination"></paginator-summary>
        </b-col>
        <b-col cols="6" sm="6" order-sm="3">
          <b-pagination v-if="(pagination.totalRows > pagination.perPage)" v-model="pagination.currentPage"
            hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />
        </b-col>
      </b-row>

      <b-table v-if="!notFound" responsive hover :stacked="cardCollectionsOptions.options.tableStacked"
        :items="filtered" :fields="fields" class="mb-0 p-sm-1">

        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.size }">
        </template>

        <template #head(index)>
          #
        </template>
        <template #cell(index)="data">
          <span v-if="cardCollectionsOptions.options.tableStacked"><strong># </strong></span>{{data.index + 1 + (pagination.perPage * (pagination.currentPage - 1))}}
        </template>

        <template #head(payer)>
          {{ $t('components.cards.meli.mp.collections.payer')}}
        </template>
        <template #cell(payer)="data">
          <collection-payer :payer="data.item.payer" :collectionId="data.item.id" :viewDetail="isPayerUser"
            :showDetail="cardCollectionsOptions.options.showDetail" />
        </template>

        <template #head(collection)>
          {{ $t('components.cards.meli.mp.collections.description')}}
        </template>
        <template #cell(collection)="data">
          <collection-identification :collection="data.item" :viewDetail="isPayerUser"
            :showDetail="cardCollectionsOptions.options.showDetail" :options="cardCollectionsOptions.options" />
        </template>

        <template #head(status)>
          {{ $t('components.cards.meli.mp.collections.status')}}
        </template>
        <template #cell(status)="data">
          <collection-detail :collection="data.item" :viewDetail="isPayerUser"
            :showDetail="cardCollectionsOptions.options.showDetail" />
        </template>

        <template #head(dates)>
          {{ $t('components.cards.meli.mp.collections.dates')}}
        </template>
        <template #cell(dates)="data">
          <collection-dates :collection="data.item" :viewDetail="isPayerUser"
            :showDetail="cardCollectionsOptions.options.showDetail" />
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <br />
      <b-pagination v-if="(pagination.totalRows > pagination.perPage) && (y > 250)" v-model="pagination.currentPage"
        hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol,
  VBTooltip, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import defaultConfig from '@core-md/config'
import TableInfo from '@core-md/components/TableInfo.vue'
import PaginatorSummary from '@core-md/components/PaginatorSummary.vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import stringHelper from '@core-md/mixins/stringHelper'
import dateHelper from '@core-md/mixins/dateHelper'
import Excel from '@core-md/utils/excel'
import { $themeConfig } from '@themeConfig'
import { useWindowScroll } from '@vueuse/core'
import cardCollectionsOptions from './_cardCollectionsOptions'
import CollectionPayer from './CollectionPayer.vue'
import CollectionIdentification from './CollectionIdentification.vue'
import CollectionDetail from './CollectionDetail.vue'
import CollectionDates from './CollectionDates.vue'
import Search from './Search.vue'
import Toolbar from './Toolbar.vue'
import simpleAccountSelector from '../../../components/SimpleAccountSelector.vue'
import meliCollections from '@/services/meli/collections'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    simpleAccountSelector,
    TableInfo,
    PaginatorSummary,
    CollectionPayer,
    CollectionIdentification,
    CollectionDetail,
    CollectionDates,
    Search,
    Toolbar,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
    stringHelper,
    dateHelper,
  ],
  data() {
    return {
      selectedAccount: null,
      cardCollectionsOptions,
      fields: [
        {
          key: 'index',
          label: '',
          sortable: false,
          tdClass: 'pl-0 pl-sm-1 text-left',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'payer',
          label: '',
          sortable: false,
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'collection',
          label: '',
          sortable: false,
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'status',
          label: '',
          sortable: false,
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
        {
          key: 'dates',
          label: '',
          sortable: false,
          tdClass: 'pl-0 pl-sm-1',
          thClass: 'pl-0 pl-sm-1 text-left',
        },
      ],
      results: [],
      filtered: [],
      pagination: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        sortBy: 'date_created',
        sortDesc: true,
      },
      userIds: [],
      filter: null,
      formSubmitted: false,
      processing: false,
      unWatchSelectedAccount: null,
    }
  },
  computed: {
    accountLoaded() {
      return this.selectedAccount.loading >= 100
    },
    notFound() {
      return this.pagination.totalRows === 0
    },
    usersPublic() {
      return this.$store.state.meli.users
    },
    itemsProcessed() {
      return ((this.results.length - this.userIds.length) / this.results.length) * 100
    },
    currentPage() {
      return this.pagination.currentPage
    },
    isPayerUser() {
      return true
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    const { y } = useWindowScroll()

    return {
      y,
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.getData()
        }
      })
    }
    this.$store.dispatch('meli/initialize')
    this.getData()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    getData(page = 1) {
      if (this.selectedAccount) {
        this.results = []
        this.pagination.totalRows = 0
        this.pagination.currentPage = page
        this.formSubmitted = true
        let request = {
          accessToken: this.selectedAccount.access_token,
          sellerId: this.selectedAccount.seller_id,
          collectorId: 'me',
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.sortDesc ? 'desc' : 'asc',
          limit: this.pagination.perPage,
          offset: (this.pagination.currentPage - 1) * this.pagination.perPage,
        }

        request = this.applyFilter(request)

        meliCollections.getCollections(request).then(response => {
          if (response.status === 200) {
            this.userIds = []

            response.response.results.forEach(t => {
              const userDetail = this.usersPublic.find(user => user.id.toString() === t.payer.id)
              if (!userDetail) {
                this.userIds.push(t.payer.id)
                t.payer.nickname = '' // eslint-disable-line
                t.payer.points = 0 // eslint-disable-line
                t.payer.permalink = '' // eslint-disable-line
              } else {
                t.payer.nickname = userDetail.nickname // eslint-disable-line
                t.payer.points = userDetail.points // eslint-disable-line
                t.payer.permalink = userDetail.permalink // eslint-disable-line
              }

              this.results.push(t)
            })

            this.pagination.totalRows = response.response.paging.total
            this.getDetailsByIds()
          }
          this.formSubmitted = false
          this.filtered = this.results
        }).catch(data => {
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    applyFilter(request) {
      const aux = request
      if (this.filter) {
        const statuses = []
        const paymentTypes = []
        const paymentMethods = []
        const payerIds = []
        const ids = []
        const payerEmails = []
        const ranges = []
        this.filter.forEach(value => {
          if (value.option) {
            switch (value.option) {
              case 'id':
                ids.push(value.value_id)
                break
              case 'payer.id':
                payerIds.push(value.value_id)
                break
              case 'payer.email':
                payerEmails.push(value.value_id)
                break
              case 'status':
                statuses.push(value.value_id)
                break
              case 'payment_type_id':
                paymentTypes.push(value.value_id)
                break
              case 'payment_method_id':
                paymentMethods.push(value.value_id)
                break
              case 'range':
                ranges.push(value)
                break
              default:
                break
            }
          }
        })
        if (ids.length > 0) {
          aux.id = ids.join(',')
        }
        if (payerEmails.length > 0) {
          aux.payerEmail = payerEmails.join(',')
        }
        if (payerIds.length > 0) {
          aux.payerId = payerIds.join(',')
        }
        if (statuses.length > 0) {
          aux.status = statuses.join(',')
        }
        if (paymentTypes.length > 0) {
          aux.paymentTypeId = paymentTypes.join(',')
        }
        if (paymentMethods.length > 0) {
          aux.paymentMethodId = paymentMethods.join(',')
        }
        if (ranges.length > 0) {
          aux.range = ranges[0] // eslint-disable-line
        }
      }
      return aux
    },
    getDetailsByIds() {
      if (this.userIds.length > 0) {
        const start = 0
        const end = defaultConfig.MELI_MULTIGET_QTY
        const itemToProcess = this.userIds.slice(start, end)
        this.userIds = this.userIds.slice(end)
        const params = {
          accessToken: this.selectedAccount.access_token,
          ids: itemToProcess,
        }
        this.$store.dispatch('meli/refreshUserDetails', params).then(response => {
          this.updateUsers(response)

          setTimeout(() => {
            this.getDetailsByIds()
          }, 2000)
        })
      }
    },
    updateUsers(users) {
      this.results.forEach(collection => {
        const updatedUser = users.find(result => result.id.toString() === collection.payer.id)
        if (updatedUser) {
          collection.payer.nickname = updatedUser.nickname // eslint-disable-line
          collection.payer.points = updatedUser.points // eslint-disable-line
          collection.payer.permalink = updatedUser.permalink // eslint-disable-line
        }
      })
    },
    executeFilter(option, valueId, text) {
      if (option) {
        const op = {
          option,
          value_id: valueId,
          text,
        }
        this.filter = [op]
      } else {
        this.filter = []
      }
      this.getData()
    },
    executeSort(field, sortDesc) {
      this.pagination.sortBy = field
      this.pagination.sortDesc = sortDesc
      this.getData(this.pagination.currentPage)
    },
    changeSelectedAccount(selected) {
      this.selectedAccount = selected
      this.getData()
    },
    clearSearch() {
      this.filter = ''
      this.filtered = this.results
      this.pagination.totalRows = this.results.length
    },
    downloadFile(fileFormat) {
      const data = {
        worksheetName: this.$i18n.t('components.cards.meli.settings.blocklists.orders_sheet_name'),
        fileName: this.$i18n.t('components.cards.meli.settings.blocklists.orders_file_name') + fileFormat, // eslint-disable-line
        format: fileFormat,
      }
      const fontStyle = {
        name: 'Calibri', size: 11,
      }
      data.columns = [
        { header: this.$i18n.t('meli.identification.id'), width: 15, style: { font: fontStyle } },
        { header: this.$i18n.t('meli.identification.nickname'), width: 65, style: { font: fontStyle } },
      ]
      data.rows = this.results.map(t => {
        const r = [
          t.id,
          t.nickname,
        ]
        return r
      })

      Excel.downloadFile(data)
    },
  },
  watch: {
    currentPage(page) {
      this.getData(page)
    },
  },
}
</script>
